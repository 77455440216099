<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      :height="$vuetify.breakpoint.smAndDown ? 60 : 100"
    >

      <base-img
        :src="require(`@/assets/Ronghao_logo_appbar.png`)"
        :max-width="$vuetify.breakpoint.smAndDown ? 60 : 100"
        :max-height="$vuetify.breakpoint.smAndDown ? 60 : 100"
        @click=fnGoToHome
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
          <v-menu
            open-on-hover
            top
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar>
                  <v-img
                    max-height="auto"
                    :src="fnGetLanguage.src"
                  />
                </v-avatar>
                &nbsp;{{ fnGetLanguage.title }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(language, index) in languages"
                :key="index"
                @click="changeLocale(language)"
              >
                <v-list-item-avatar>
                  <v-img
                    :src="language.src"
                  />
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ language.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      locale: '',
      drawer: null,
      items: [
        'Home',
        'About',
        'Solutions',
        'Portfolio',
        'Training & Education',
        'Brochure',
        'Contact',
      ],
      languages: [
        {
          title: 'English',
          locale: 'en',
          src: require('@/assets/flag_us.png'),
        },
        {
          title: '한국어',
          locale: 'ko',
          src: require('@/assets/flag_ko.png'),
        },
      ],
    }),
    computed: {
      fnGetLanguage () {
        return this.languages[0]
      },
    },
    methods: {
      fnGoToHome () {
        this.$router.push('/')
      },
      changeLocale (language) {
        if (language.locale === 'ko') {
          window.open('https://kr.rhrobotics.com/', '_self')
        }
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
